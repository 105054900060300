const adSetList = [
  { id: 1, name: "open LP 4016", link: "https://two-page-4016.pages.dev/" },
  {
    id: 1,
    name: "open LP 4016 with UTM test111",
    link: "https://two-page-4016.pages.dev/?utm_source=test1&utm_campaign=test11&utm_content=test111",
  },

  { id: 2, name: "open LP 4017", link: "https://three-pages-4017.pages.dev/" },
  { id: 2, name: "open LP 4018", link: "https://ad-set-pages-4018.pages.dev/" },
];

function App() {
  return (
    <>
      <div
        style={{
          margin: "30px auto",
          width: "600px",
          display: "flex",
          gap: "20px",
        }}
      >
        {adSetList.map((adSet) => (
          <button
            key={adSet?.id}
            onClick={() => window.open(adSet?.link, "_blank")}
          >
            {adSet?.name}
          </button>
        ))}
      </div>
    </>
  );
}

export default App;
